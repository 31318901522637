import { comma2Dot } from '@/utils/string'

export function prepareFormDataCommaToDot (data, keyStringArray) {
  const prepared = {
    ...data
  }

  for (const key of keyStringArray) {
    if (key in prepared) {
      if (typeof prepared[key] === 'number') {
        prepared[key] = prepared[key].toString()
      } else {
        prepared[key] = comma2Dot(prepared[key])
      }
    }
  }

  return prepared
}
