const mixinModalHandler = {
  methods: {
    async modalHandler (handlers, callback) {
      try {
        handlers.setLoading(true)
        await callback()
      } finally {
        handlers.setLoading(false)
        handlers.close()
      }
    }
  }
}

export {
  mixinModalHandler
}
