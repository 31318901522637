<template>
  <nav class='anchor-bar' :class="isOpen && 'is-open'">
    <button
      class='anchor-bar__button'
      type='button'
      @click="toggleIsOpen"
    >
      <svgicon
        class="anchor-bar__button-icon svg-icon--rollup"
        icon="button/rollup"
        width="13"
        height="9"
      />
    </button>

    <div class="anchor-bar__wrapper custom-scroll">
      <div class="anchor-bar__list-wrap" >
        <ul class='anchor-bar__list'>
          <li
            v-for="item of anchorList"
            :key="item.elId"
            class='anchor-bar__item'
          >
            <AnchorBarLink
              :class="activeId === item.elId && 'is-active'"
              :anchor='item'
              @change-active="setActive"
            />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import AnchorBarLink from '@/components/ui/AnchorBar/AnchorBarLink.vue'
import { mixinIsOpen } from '@/mixins/mixinIsOpen'

export default {
  name: 'AnchorBar',
  components: { AnchorBarLink },
  mixins: [mixinIsOpen],
  props: {
    anchorList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    activeId: ''
  }),
  methods: {
    setActive (elId) {
      this.activeId = elId
    }
  }
}
</script>

<style lang='scss'>
.anchor-bar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: var(--z-index__achor-bar);
  display: flex;
  align-items: center;
  transition: transform var(--transition-duration) var(--transition-timing-function);

  &__button {
    width: 30px;
    height: 30px;
    border-radius: 11px 0 0 11px;
    color: var(--color__periwinkle-crayola);
    background-color: var(--color-main);
  }

  &__button-icon {
    transform: rotate(-90deg);
  }

  &__wrapper {
    position: fixed;
    height: 100vh;
    right: 0;
    transform: translateX(101%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__list-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list {
    margin: 25px 0;
    writing-mode: vertical-lr;
    display: flex;
    gap: var(--s);
    border-radius: 2px;
    padding: 15px;
    background-color: var(--color__white);
    box-shadow: 4px 4px 11px rgba(209, 217, 230, 0.44);
  }

  &__item:not(:last-child) {
    display: flex;
    align-items: center;
    gap: var(--s);

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 77px;
      background-color: var(--color__periwinkle-crayola);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding-top: 20px;
    color: var(--color__pearl-dark-gray);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 9px;
      height: 9px;
      border-radius: 100%;
      background-color: var(--color__periwinkle-crayola);
    }

    &.is-active {
      color: var(--color-main);

      &::before {
        background-color: var(--color-main);
      }
    }
  }

  &.is-open {
    transform: translate(-54px);

    .anchor-bar {
      &__button {
        color: var(--color-main);
        background-color: var(--color__white);
      }

      &__button-icon {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
