export function prepareFormDataId (data, keyStringArray) {
  const prepareFormData = {
    ...data
  }

  for (const key of keyStringArray) {
    if (key in prepareFormData) {
      const newKey = key + '_id'
      prepareFormData[newKey] = prepareFormData[key]?.id || null

      delete prepareFormData[key]
    }
  }

  return prepareFormData
}
