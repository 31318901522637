<template>
  <tr>
    <td :colspan="containsColumnYear ? '2' : ''">
      Итого:
    </td>

    <td
      v-for="(col, i) in totalColumns"
      :key="i + 'td'"
      v-html="col.handlerFormat ? col.handlerFormat(totalItem[col.name]) : totalItem[col.name]"
    />
  </tr>
</template>

<script>
import { fixedNumberCopy } from '@/utils/number'

export default {
  name: 'TableTotalItem',
  props: {
    columns: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    containsColumnYear () {
      return this.columns[0].name === 'year'
    },
    totalColumns () {
      return this.containsColumnYear ?
        this.columns.slice(2, this.columns.length)
        : this.columns.slice(1, this.columns.length)
    },
    totalItem () {
      const totalItem = {}

      this.totalColumns.forEach(col => {
        totalItem[col.name] = 0

        for (const item of this.items) {
          if (isNaN(+item[col.name])) {
            totalItem[col.name] = '-'
            break
          }

          totalItem[col.name] += +item[col.name]
        }

        if (totalItem[col.name] !== '-') {
          totalItem[col.name] = fixedNumberCopy(totalItem[col.name])
        }
      })

      return totalItem
    }
  }
}
</script>
