export function isValidStageLine (stageItemArrayRef) {
  let isValid = true

  stageItemArrayRef.forEach(stageItem => {
    if (stageItem.isValid()) {
      isValid = false
    }
  })

  return isValid
}
