import { splitRelationOrders } from '@/utils/model/Order'
import { findByKeyValue } from '@/utils/array/find'

export function getRelationOrders (order) {
  const relationOrders = []

  if (order?.relation_orders?.length) {
    order.relation_orders.forEach(order => {
      relationOrders.push(order)
    })
  }

  if (order?.relation_order_text) {
    const accountNumbers = splitRelationOrders(order.relation_order_text)

    for (const accountNumber of accountNumbers) {
      if (findByKeyValue(relationOrders, 'account_number', accountNumber)) continue

      relationOrders.push({
        account_number: accountNumber,
        id: null
      })
    }
  }

  return relationOrders
}
