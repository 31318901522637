<template>
  <ModalForm
    v-model="formData"
    title="Создать новую позицию"
    class="modal--title-reset"

    text-btn-confirm="Создать"
    btn-size="xl"
    only-btn-submit

    @request="submitCreatePosition"
    @reject="close"
  >
    <div class="column gap-m mb-30">
      <VInput
        label="Артикул"
        name="article"
        required
        @input="checkHasTemplate"
      >
        <Validation for="required"/>
      </VInput>

      <VInput
        label="Количество изделий"
        name="value"
        required
        :pattern="/^\d+$/"
      >
        <Validation for="required" />
        <Validation for="pattern" key-text="num"/>
      </VInput>

      <VCheckbox
        v-model="isTemplate"
        label="Шаблон"
      />

      <transition name="fade">
        <VButton
          v-if="visibleButtonAddFindTemplate"
          text="Добавить из найденного шаблона"
          block="template-add"
          icon="add"
          @click.native="addFindTemplate"
        />
      </transition>

      <VSelect
        v-if="!isTemplate"
        label="Тип изделия"
        name="spring_type"
        required
        :options="properties.product_type"
      >
        <Validation for="required"/>
      </VSelect>

      <VSelect
        v-else
        label="Шаблон"
        name="template"
        item-text="article"
        required
        :options="articleOptions"
      >
        <Validation for="required"/>
      </VSelect>
    </div>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import Validation from '@/components/Form/Validation.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'
import VButton from '@/components/simple/button/VButton.vue'
import VSelect from '@/components/Form/VSelect.vue'

import { mapActions, mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { mixinArticles } from '@/mixins/views/mixinArticles'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { mixinTimeout } from '@/mixins/mixinTimeout'

export default {
  name: 'ModalPositionCreate',
  components: {
    VSelect,
    ModalForm,
    Validation,
    VButton,
    VCheckbox,
    VInput
  },
  mixins: [mixinArticles, mixinTimeout],

  data: () => ({
    formData: {
      article: '',
      value: '',
      spring_type: null,
      template: null
    },
    isTemplate: false,
    visibleButtonAddFindTemplate: false,
    template: null
  }),

  computed: {
    ...mapState('records', {
      properties: 'properties'
    }),
    ...mapGetters('orderDetail', {
      order: 'order'
    }),

    articleOptions () {
      return [
        ...this.articlesAll.position_templates,
        ...this.articlesAll.products
      ]
    }
  },

  created () {
    this.$store.dispatch('records/getAllArticles')
  },

  methods: {
    ...mapActions('orderDetail', {
      createPosition: 'createPosition',
      createPositionByTemplate: 'createPositionByTemplate'
    }),

    async submitCreatePosition (formData) {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        const postData = {
          ...formData,
          order_id: this.order.id
        }

        if (this.isTemplate) {
          await this.createPositionByTemplate(postData)
        } else {
          postData.type_id = formData.spring_type.id
          await this.createPosition(postData)
        }

        showAlertSuccess('Позиция создана')
        this.close()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    checkHasTemplate (article) {
      this.clearTimeoutId()
      this.findedTemplate = null
      this.visibleButtonAddFindTemplate = false

      this.setTimeoutId(() => this.findTemplateBy(article.trim()), 500)
    },

    findTemplateBy (article) {
      for (const item of this.articleOptions) {
        if (item.article === article) {
          this.visibleButtonAddFindTemplate = true
          this.findedTemplate = item
          return
        }
      }
    },

    addFindTemplate () {
      Vue.set(this.formData, 'template', this.findedTemplate)
      this.isTemplate = true
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
