<template>
  <div class="traffic-light">
    <span
      v-for="color of colorList"
      :key="color"
      class="traffic-light__circle"
      :class="[
        `color--${color}`,
        (color === activeColor) && 'active'
      ]"
    />
  </div>
</template>

<script>
export default {
  props: {
    activeColor: {
      type: String,
      required: true
    }
  },
  data: () => ({
    colorList: [
      'red',
      'orange',
      'green'
    ]
  })
}
</script>
