<template>
  <a
    class='anchor-bar__link link-reset'
    :href="`#${anchor.elId}`"
    @click.prevent="onLink"
  >
    {{ anchor.label }}
  </a>
</template>

<script>
import { scrollToStartNode } from '@/utils/dom'

export default {
  name: 'AnchorBarLink',
  props: {
    anchor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    node: null
  }),
  mounted () {
    this.setNode()
  },
  methods: {
    onLink () {
      scrollToStartNode(this.node)
      this.$emit('change-active', this.anchor.elId)
    },

    setNode () {
      this.node = document.getElementById(this.anchor.elId)
    }
  }
}
</script>
