import { fixedNumberCopy } from '@/utils/number'
import { comma2Dot } from '@/utils/string'

function transformConsignmentWeightValue (value) {
  const newValueNumber = +comma2Dot(value)
  return fixedNumberCopy(newValueNumber, 3)
}

export {
  transformConsignmentWeightValue
}
