<template>
  <div
    class="order-card"
    :class="{
      'order-card--detail': isDetail,
      'border-color--red': order.is_outer_reclamation
    }"
    :id="`order-card--${order.id}`"
  >
    <!--  left  -->
    <div class="d-flex flex-col gap-s">
      <div class="flex gap-s">
        <h3 class="flex gap-xxs">
          <svgicon
            v-if="!isDetail && showIndicator"
            class="svg-icon--order-indicator"
            icon="order-indicator"
            width="20"
          />

          Заказ №{{ order.account_number }}

          <TrafficLight
            v-if="visibleTrafficLight && trafficLightColor"
            :active-color="trafficLightColor"
          />
        </h3>

        <div v-if="order.is_pickup" class="color--green">
          <svgicon class="svg-icon--pickup" icon="button/pickup" width="23" />

          Самовывоз
        </div>

        <div v-if="order.documents_are_ready" class="color--green">
          <svgicon icon="document-ready" width="20" />

          Документы готовы
        </div>
      </div>

      <h5 v-if="!isDetail">{{ order.client_name }}</h5>

      <div class="order-card__data">
        <div class="d-flex gap-l" style="flex-wrap: wrap; row-gap: 20px;">
          <AppInfoTextBlock
            :label-text="isDetail ? 'Дата заказа' : 'Дата поступления заказа'"
            :text="order.date_order | date('date')"
          />

          <AppInfoTextBlock
            v-if="order.finished_at"
            label-text="Готов"
            :text="order.finished_at | date('date')"
          />

          <AppInfoTextBlock
            v-if="order.delivered_at"
            label-text="Отгружен"
            :text="order.delivered_at | date('date')"
          />

          <AppInfoTextBlock
            v-if="isDetail"
            label-text="Статус"
            :text="orderStatusText"
          />

          <slot name="reclamation" />
        </div>

        <AppInfoTextBlock
          v-if="!isDetail"
          label-text="Сроки"
          :text="order.timing ? order.timing.name : '-'"
        />
      </div>
    </div>

    <!--  right  -->
    <div class="order-card__info">
      <div v-if="order.status_id !== 5" class="order-card__info-item">
        <OrderDateStatus :order="order" />
      </div>

      <div class="order-card__info-item order-card__progress-wrap">
        <div class="order-card__progress">
          <AppDonutProgressBar :value="order.process" />
        </div>

        <div class="order-card__progress-title">Готовность заказа</div>
      </div>
    </div>

    <router-link
      v-if="!isDetail"
      class="absolute-full"
      :to="{
        name: 'OrderDetails',
        params: { id: order.id }
      }"
      draggable="false"
    />
  </div>
</template>

<script>
import OrderDateStatus from '@/components/production/order/OrderDateStatus'
import AppDonutProgressBar from '@/components/simple/chart/AppDonutProgressBar'
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock'
import TrafficLight from '@/components/production/order/TrafficLight'

import { mapGetters } from 'vuex'
import { getOrderStatusText } from '@/utils/order/order'

export default {
  name: 'OrderCard',
  components: {
    AppDonutProgressBar,
    OrderDateStatus,
    AppInfoTextBlock,
    TrafficLight
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    isDetail: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentUserId: 'currentUserId',
      currentUserRole: 'currentUserRole'
    }),

    showIndicator () {
      if (this.order.positions?.length) {
        for (const item of this.order.positions) {
          if (item.responsible_id === Number(this.currentUserId) && !item.statuses_line && this.order.status_id === 6) {
            return true
          }
        }
      }

      return false
    },

    orderStatusText () {
      return getOrderStatusText(this.order.status_id)
    },

    visibleTrafficLight () {
      if (this.isDetail) return false
      if (this.order.status_id !== 2 && this.order.status_id !== 6) return false

      switch (this.currentUserRole) {
        case 'admin':
        case 'director':
        case 'sales-manager':
        case 'production-manager':
        case 'brigadier':
        case 'production-master':
          return true
        default:
          return false
      }
    },

    trafficLightColor () {
      if (!this.order?.positions?.length) return ''

      if (this.currentUserRole === 'brigadier' || this.currentUserRole === 'production-master') {
        return this.trafficLightColorForBrigadier
      }

      let color = 'green'

      for (const position of this.order.positions) {
        if (position.value === position.waiting_start) {
          color = 'red'
          break
        }

        if (color === 'orange') continue

        if (position.waiting_start > 0) {
          color = 'orange'
        }
      }
      return color
    },
    trafficLightColorForBrigadier () {
      let color = ''

      for (const position of this.order.positions) {
        const stageLine = position?.statuses_line?.statuses
        if (!stageLine?.length) continue

        const stageOneResponsibleId = stageLine[0]?.user?.id
        if (this.currentUserId !== stageOneResponsibleId) continue

        if (!color) {
          color = 'green'
        }

        if (position.value === position.waiting_start) {
          color = 'red'
          break
        }

        if (color === 'orange') continue

        if (position.waiting_start > 0) {
          color = 'orange'
        }
      }
      return color
    }
  }
}
</script>
