<template>
  <div class="table" :class="isSticky && 'table--sticky custom-scroll'" :style="styleContainer" ref="container">
    <table
        :class="isTreeTable && 'table-tree'"
        :cols="columns.length"
    >
      <thead class="table__head">
        <tr v-if="columnsBefore.length">
          <th
            v-for="(col, i) in columnsBefore"
            :key="i"
            :class="col.classes"
            :colspan="col.colspan || 1"
            :rowspan="col.rowspan || 1"
          >
            {{ col.title }}
          </th>
        </tr>

        <tr>
          <th
            v-for="(col, idx) in cols"
            :key="idx"
            class="th--border-t"
            :colspan="col.colspan || 1"
            :rowspan="col.rowspan || 1"
          >
            <span
              v-if="col.sortable"
              class="th--sort"
              :class="{'reverse': col.sortDesc}"
              @click="sortedHandler(col)"
            >
              <span class="mr-8">{{ col.title }}</span>

              <svgicon
                icon="arrow"
                width="13"
                height="8"
              />
            </span>

            <span v-else>{{ col.title }}</span>
          </th>
        </tr>
      </thead>

      <tbody v-if="isTreeTable">
        <TableTreeItem
          v-for="(item, i) in items"
          :key="i"
          :item="item"
          :columns="columns"
          :children-keys="treeChildrenKeys"
          :visible-child="isOpenTree"
        />

        <TableTotalItem
          v-if="withTotal"
          ref="total"
          :items="items"
          :columns="columns"
        />
      </tbody>

      <tbody v-else class="table__body">
        <tr
          v-for="(item, i) in items"
          :id="isChangelog ? 'item-changelog--' + item.id : ''"
          :key="i + 'tr'"
          :class="{'tr__hover' : rowActive, 'view_row' : checkViewRow(item)}"
          @click="rowClick(item)"
        >
          <td
              v-for="(col, i) in columns"
              :key="i + 'td'"
          >
            <span
              v-if="!col.isLink"
              v-html="col.handlerFormat ? col.handlerFormat(item[col.name]) : item[col.name]"
            />

            <TableLinkList
              v-if="col.isLink && Array.isArray(item[col.name])"
              :list="item[col.name]"
              :router-data="col.routerData"
            />
          </td>
        </tr>

        <TableTotalItem
          v-if="withTotal"
          ref="total"
          :items="items"
          :columns="columns"
        />
      </tbody>
    </table>

    <Spinner v-if="loading" />

    <slot name="placeholder" v-if="!items.length && !loading" >
      <div class="no-records-placeholder h3">
        Нет записей
      </div>
    </slot>
  </div>
</template>

<script>
import TableTreeItem from '@/components/ui/Table/TableTreeItem'
import TableLinkList from '@/components/ui/Table/TableLinkList'
import TableTotalItem from '@/components/ui/Table/TableTotalItem'

export default {
  name: 'AppTable',
  components: {
    TableTotalItem,
    TableLinkList,
    TableTreeItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    columnsBefore: {
      type: Array,
      required: false,
      default: () => []
    },
    columns: {
      type: Array,
      required: true
    },
    // Для преобразования columns, если head из нескольких строк
    handlerColumns: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowActive: {
      type: Boolean,
      default: false
    },
    withTotal: {
      type: Boolean,
      default: false
    },
    isTreeTable: {
      type: Boolean,
      default: false
    },
    treeChildrenKeys: {
      type: Object,
      required: false
    },
    isOpenTree: {
      type: Boolean,
      required: false,
      default: true
    },
    isSticky: {
      type: Boolean,
      default: false
    },
    viewItems: {
      type: Array,
      default: () => []
    },
    isChangelog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mTop: 0
    }
  },
  computed: {
    cols () {
      if (!this.handlerColumns) return this.columns
      return this.handlerColumns(this.columns)
    },
    styleContainer () {
      if (this.isSticky) {
        return {
          'max-height': `calc(100vh - ${this.mTop + 30}px)`
        }
      }

      return {}
    }
  },

  mounted () {
    this.mTop = this.$refs.container.getBoundingClientRect().top
  },
  methods: {
    sortedHandler (col) {
      this.$emit('sorted', col)
    },

    rowClick (item) {
      if (this.isChangelog) {
        this.viewItems.push(item.id)
      }

      this.$emit('rowClick', item)
    },

    checkViewRow (item) {
      return !!this.viewItems?.includes(item.id)
    }
  }
}
</script>
