import validators from '@/utils/form/validators'

const mixinConsignmentValidationDirtyWeightMinValue = {
  methods: {
    validationDirtyWeightMinValue (value) {
      return validators.minValueComma2Dot(value, this.consignment.weight_clear)
    },
    validationSplitDirtyWeightMinValue (value) {
      return validators.minValueComma2Dot(value, this.formData.split_weight)
    }
  }
}

export {
  mixinConsignmentValidationDirtyWeightMinValue
}
