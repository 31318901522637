<template>
  <ModalForm
    v-model="formData"
    title="Копировать позицию"
    class="modal--title-reset"

    text-btn-confirm="Подтвердить"
    text-btn-reject="Отмена"

    @request="submitCopyPosition"
    @reject="close"
  >
    <div class="column gap-m mb-30">
      <VSelect
        label="Позиция"
        name="positions"
        required
        :options="copyPositions"
      >
        <Validation for="required"/>
      </VSelect>

      <VInput
        label="Количество изделий"
        name="value"
        required
        :pattern="/^\d+$/"
      >
        <Validation for="required" />
        <Validation for="pattern" key-text="num"/>
      </VInput>
    </div>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import Validation from '@/components/Form/Validation.vue'
import VSelect from '@/components/Form/VSelect.vue'

import { mapActions, mapGetters } from 'vuex'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { mixinTimeout } from '@/mixins/mixinTimeout'

export default {
  name: 'ModalPositionCopy',
  components: {
    VSelect,
    ModalForm,
    Validation,
    VInput
  },
  mixins: [mixinTimeout],

  data: () => ({
    formData: {
      position: null,
      value: ''
    }
  }),

  computed: {
    ...mapGetters('orderDetail', {
      copyPositions: 'copyPositions'
    })
  },

  methods: {
    ...mapActions('orderDetail', {
      copyPosition: 'copyPosition'
    }),

    async submitCopyPosition (formData) {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        const postData = {
          value: formData.value,
          position_id: formData.positions.id
        }

        await this.copyPosition(postData)

        showAlertSuccess('Позиция скопирована')
        this.close()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
