<template>
  <div>
    <VButton
      text="Печать ТН"
      size="sm"
      icon="print"
      @click.native="openModal"
    />

    <ModalPrint
      v-if="isModal"
      :init-print-type="options[0].value"
      :print-options="options"
      @request="onSubmit"
      @reject="closeModal"
    />

    <ModalFilesPreview
      v-if="isFilePreview"
      :files="files"
      :init-active-file-idx="activeFileIdx"
      @close="isFilePreview = false"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import ModalPrint from '@/components/ui/Modal/ModalPrint.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { waybillModel } from '@/entities/waybill'
import { PRINT_OPTIONS } from '../model/const'
import { showAlertError } from '@/utils/store/alert'
import { ReportApi } from '@/api/report/ReportApi'
import ModalFilesPreview from '@/components/files/ModalFilesPreview'

export default {
  components: {
    ModalPrint,
    VButton,
    ModalFilesPreview
  },
  mixins: [mixinModal],
  props: {
    waybill: {
      type: waybillModel.Waybill,
      required: true
    }
  },
  computed: {
    options: () => PRINT_OPTIONS
  },
  data: () => ({
    files: [],
    activeFileIdx: 0,
    isFilePreview: false
  }),
  mounted () {
    this.createFiles()
  }, 

  methods: {
    async createFiles () {
      try {
      this.$store.commit('START_IS_SENDING_REQUEST_MODAL')
      this.files = []
      const response = await ReportApi.getBy('waybills', {
        waybill_ids: [this.waybill.id]
      })
      const file = {
        url_pdf: response.data.url_pdf,
        url_docx: response.data.url_docx,
        name: response.data.url_pdf.split('/').at(-1)
      }
        this.files.push(file)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },
    async onSubmit () {
      await this.createFiles()
      this.isFilePreview = true
      await this.closeModal()
    }
  }
}
</script>
