<template>
  <div class="order-prob">
    <button
      class="order-prob__button gap-xxs btn-reset color--orange"
      @click="openModal"
    >
      <span class="center semi-bold">
        <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.7899" cy="16.9582" r="4.95458" transform="rotate(-12.3065 14.7899 16.9582)" stroke="#FF9900" stroke-width="1.5"/>
            <path d="M19.1538 19.7588L23.5184 22.5601" stroke="#FF9900" stroke-width="1.5"/>
            <path d="M13.7007 3.25264C14.278 2.25208 15.722 2.25208 16.2993 3.25264L27.8365 23.2504C28.4134 24.2504 27.6917 25.5 26.5372 25.5H3.46282C2.30833 25.5 1.58662 24.2504 2.16354 23.2504L13.7007 3.25264Z" stroke="#FF9900"/>
        </svg>
      </span>

    Найдены пробники
    </button>

    <BaseModal v-if="isModal" @close="closeModal">
      <h3 class="modal__title color--orange">Пробники по заказу</h3>

      <div class="column gap-xxs">
        <router-link
          v-for="prob of links"
          :key="prob.id"
          class="link"
          :to="{ name: 'OrderDetails', params: { id: prob.id } }"
        >
          {{ prob.text }}
        </router-link>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { formatDate } from '@/utils/format/date'

export default {
  components: { BaseModal },
  mixins: [mixinModal],
  props: {
    probes: {
      type: Array,
      required: true
    }
  },

  computed: {
    links () {
      let date = ''
      let number = ''

      return this.probes.map(prob => {
        date = formatDate(prob.created_at)
        number = `: Заказ №${prob.account_number}`
        return {
          id: prob.id,
          text: `${date}${number}`
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
