<template>
  <ModalForm
    v-model="formData"
    :title="title"
    text-btn-confirm="Продолжить"
    btn-size="xl"
    only-btn-submit

    @request="openWeightDirtyModal"
    @reject="onReject"
  >
    <div v-if="firstModal">
      <RadioGroup name="type" class="mb-30" :options="types" />

      <VSelect
        v-if="stageList.length"
        label="Отправить на этап"
        class="mb-30"
        name="status"
        :options="stageList"
      />

      <VInput
        v-if="formData.type == 'split_consignment'"
        label="Укажите кол-во штук:"
        name="split_qty"
        class="mb-30"
        required
        :pattern="pattern.num"
        :max-value="consignment.value - 1"
        :min-value="1"
        @changeValue="calcWeight"
      >
        <Validation for="required">Поле обязательно</Validation>
        <Validation for="pattern" key-text="num"/>
        <Validation for="maxValue">Кол-во не может быть больше {{ consignment.value - 1 }}</Validation>
        <Validation for="minValue">Кол-во не может быть меньше 1</Validation>
      </VInput>

      <VInput
        v-if="formData.type == 'split_consignment'"
        label="Укажите вес в кг:"
        name="split_weight"
        class="mb-30"
        :pattern="pattern.num100"
        @changeValue="calcQty"
      >
        <Validation for="pattern" key-text="num100"/>
      </VInput>
    </div>

    <div v-else>
      <VInput
        label="Масса:"
        name="weight_dirty"
        class="mb-30"
        required
        :pattern="/^[0-9]\d*[.,]?(\d{0,3}?)$/"
        :custom-validation="formData.type === 'full_consignment' ? validationDirtyWeightMinValue : validationSplitDirtyWeightMinValue"
      >
        <Validation for="required">Поле обязательно</Validation>
        <Validation for="pattern">Только цифры с точностью до тысячных</Validation>
        <Validation for="customValidation">Общий вес не может быть меньше {{formData.type === 'full_consignment' ? consignment.weight_clear : formData.split_weight }}</Validation>
      </VInput>
    </div>
  </ModalForm>
</template>

<script>
import VInput from '@/components/Form/Vinput/VInput.vue'
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import Validation from '@/components/Form/Validation.vue'

import { mixinModal } from '@/mixins/modal/mixinModal'
import { PATTERN_LIST } from '@/const/validation'
import RadioGroup from '@/components/Form/Radio/RadioGroup.vue'
import { showAlertWarning } from '@/utils/store/alert'
import VSelect from '@/components/Form/VSelect.vue'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'
import { fixedNumberCopy } from '@/utils/number'
import { mapGetters } from 'vuex'
import { mixinConsignmentValidationDirtyWeightMinValue } from '@/mixins/form/validation/consignment/mixinConsignmentValidationDirtyWeightMinValue'
import { toNum } from '@/utils/string'
import { transformConsignmentWeightValue } from '@/utils/pages/Production/consignment/transform'

export default {
  name: 'ModalConsignmentSplit',
  components: {
    VSelect,
    RadioGroup,
    Validation,
    ModalForm,
    VInput
  },
  mixins: [
    mixinModal,
    mixinConsignmentValidationDirtyWeightMinValue
  ],
  props: {
    consignment: {
      type: Object,
      required: true
    },
    isFirstStage: {
      type: Boolean,
      default: false
    },
    stageLine: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      formData: {
        type: '',
        weight_dirty: '',
        split_qty: '',
        split_weight: '',
        status: ''
      },
      pattern: PATTERN_LIST,
      firstModal: true,
      types: [
        {
          title: 'Передать частично',
          value: 'split_consignment',
          disabled: +this.consignment.value === 1
        },
        {
          title: 'Передать всю партию ' + this.consignment.value + ' шт.',
          value: 'full_consignment'
        }
      ]
    }
  },

  computed: {
    getTitleModalWeight () {
      let title = 'Укажите общий вес потраченного металла '
      if (this.formData.type === 'split_consignment') {
        title += 'на ' + this.formData.split_qty + ' шт.'
      }

      return title + ' в КГ'
    },

    stageList () {
      if (!this.stageLine?.length) return []

      const indexCurrentStage = this.stageLine.findIndex(stage => stage.status.id === this.consignment.status.id)

      return this.stageLine
        .slice(indexCurrentStage + 1, -3)
        .map(stage => ({
          id: stage.status.id,
          name: stage.status.name
        }))
    },

    title () {
      if (this.firstModal) {
        return 'Передать партию'
      } else {
        return this.getTitleModalWeight
      }
    }
  },

  methods: {
    ...mapGetters('permission', {
      permissionUpdateConsignmentWeight: 'permissionUpdateConsignmentWeight'
    }),

    async stageNextHandler (data) {
      if (data.type === 'split_consignment') {
        await this.split()
      } else {
        await this.stageNext()
      }
    },

    async openWeightDirtyModal (data) {
      if (!data.type) {
        showAlertWarning('Пожалуйста, выберите способ передачи партии')
        return
      }

      if (this.isFirstStage && this.permissionUpdateConsignmentWeight && !this.hasDirtyWeight() && !this.formData.weight_dirty) {
        this.firstModal = false
        this.calcWeight(this.formData?.split_qty || null)
        return
      }

      await this.stageNextHandler(data)
    },
    hasDirtyWeight () {
      if (this.consignment.warehouse) return true
      if (this.consignment.is_without_winding) return true
      return !!this.consignment?.weight_dirty
    },

    async stageNext () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
          const data = {
            changer_id: this.$store.getters.currentUserId,
            status_id: this.formData?.status?.id || null,
            weight_dirty: this.formData?.weight_dirty || null
          }

          const response = await ConsignmentController.goStageNext(this.consignment.id, data)
          if (response?.status !== 200) return

          this.$emit('goStageNext', response.data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    async split () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        const data = {
          value: this.formData?.split_qty || null,
          weight_dirty: this.formData?.weight_dirty || null,
          status_id: this.formData?.status?.id || null
        }
        data.weight_dirty = transformConsignmentWeightValue(data.weight_dirty)

        const response = await ConsignmentController.goSplit(this.consignment.id, data)
        if (response?.status !== 200) return

        response.data.current_consignment = this.consignment.id

        this.$emit('goSplit', response.data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    onReject () {
      this.$emit('reject')
    },

    calcQty (weight) {
      weight = toNum(weight)
      if (!weight) {
        this.formData.split_qty = ''
        return
      }

      const qty = this.consignment.value * weight / this.consignment.weight_clear
      this.formData.split_qty = qty.toFixed()
    },

    calcWeight (qty) {
      qty = toNum(qty)
      if (!qty) {
        this.formData.split_weight = ''
        return
      }

      const weight = qty * this.consignment.weight_clear / this.consignment.value

      this.formData.split_weight = fixedNumberCopy(weight, 2)
    }
  }
}
</script>
