<template>
  <div class="order-reclamation-external">
    <button
      class="order-reclamation-external__button gap-xxs btn-reset color--red"
      @click="openModal"
    >
      <span class="center semi-bold">
        <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.06 9.598L15.88 17.86H14.422L14.224 9.598H16.06ZM15.16 19.714C15.52 19.714 15.82 19.834 16.06 20.074C16.3 20.314 16.42 20.608 16.42 20.956C16.42 21.304 16.3 21.604 16.06 21.856C15.82 22.096 15.52 22.216 15.16 22.216C14.812 22.216 14.518 22.096 14.278 21.856C14.038 21.604 13.918 21.304 13.918 20.956C13.918 20.608 14.038 20.314 14.278 20.074C14.518 19.834 14.812 19.714 15.16 19.714Z" fill="#F74B4B"/>
            <path d="M13.7007 3.25264C14.278 2.25208 15.722 2.25208 16.2993 3.25264L27.8365 23.2504C28.4134 24.2504 27.6917 25.5 26.5372 25.5H3.46282C2.30833 25.5 1.58662 24.2504 2.16354 23.2504L13.7007 3.25264Z" stroke="#F74B4B"/>
        </svg>
      </span>

      Внешние рекламации
    </button>

    <BaseModal v-if="isModal" @close="closeModal">
      <h3 class="modal__title color--red">Рекламации по заказу</h3>

      <div class="column gap-xxs">
        <router-link
          v-for="reclamation of links"
          :key="reclamation.id"
          class="link"
          :to="{ name: $store.state.pages.reclamationPages.details, params: { id: reclamation.id } }"
        >
          {{ reclamation.text }}
        </router-link>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { formatDate } from '@/utils/format/date'

export default {
  components: { BaseModal },
  mixins: [mixinModal],
  props: {
    reclamations: {
      type: Array,
      required: true
    }
  },

  computed: {
    links () {
      let date = ''
      let position = ''

      return this.reclamations.map(reclamation => {
        date = formatDate(reclamation.created_at)
        position = reclamation.position ? `: №${reclamation.position.sort} ${reclamation.position.type.name} (${reclamation.position.value})` : reclamation.position_number || ''

        return {
          id: reclamation.id,
          text: `${date}${position}`
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
