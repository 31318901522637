<template>
  <ModalForm
    v-model="formData"
    title="Восстановить заказ"
    class="modal--title-reset"

    text-btn-confirm="Восстановить"
    text-btn-reject="Отмена"

    @request="submitRestoreOrder"
    @reject="close"
  >
    <div class="column gap-m mb-30">
      <VInput
        label="Введите ID сделки из AmoCRM"
        name="external_id"
        :custom-validation="customValidation"
      >
        <Validation for="customValidation">Необходимо заполнить поле или активировать чекбокс</Validation>
      </VInput>

      <VCheckbox
        v-model="isCheckbox"
        name="checkbox"
        label="Не привязывать сделку из AmoCRM"
      />
    </div>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import Validation from '@/components/Form/Validation.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'

import { mapActions, mapGetters, mapState } from 'vuex'
import { mixinTimeout } from '@/mixins/mixinTimeout'
import { showAlertError } from '@/utils/store/alert'

export default {
  name: 'ModalOrderRestore',
  components: {
    ModalForm,
    Validation,
    VCheckbox,
    VInput
  },
  mixins: [mixinTimeout],

  data: () => ({
    formData: {
      external_id: null
    },
    isCheckbox: false
  }),

  computed: {
    ...mapState('records', {
      properties: 'properties'
    }),
    ...mapGetters('orderDetail', {
      order: 'order'
    }),
    externalId () {
      return this.formData.external_id
    }
  },

  methods: {

    ...mapActions('orderDetail', {
      restoreOrder: 'restoreOrder'
    }),

    async submitRestoreOrder (formData) {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        let externalId = null
        if (!formData.checkbox && formData.external_id) {
          externalId = formData.external_id
        }

        await this.restoreOrder({
          id: this.order.id,
          externalId: externalId
        })

        this.close()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    close () {
      this.$emit('close')
    },

    customValidation () {
      return this.externalId || this.isCheckbox
    }
  }
}
</script>
