export const mixinTableItem = {
  props: {
    item: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  }
}
