<template>
  <div class="donut-progress">
    <div class="donut-progress__svg">
      <svg width="100%" height="100%" viewBox="0 0 42 42">
        <circle class="donut-progress__progress-bg-circle" cx="21" cy="21" r="20"></circle>
        <circle class="donut-progress__inner-bg-circle" cx="21" cy="21" r="18"></circle>
        <circle class="donut-progress__progress-circle" cx="21" cy="21" r="19" fill="transparent"
                stroke-width="2"
                stroke-dasharray="119.32 119.32"
                :stroke-dashoffset="setPercentFill(value)"
        />
      </svg>

      <div class="donut-progress__value" :class="value === 0 ? 'deactive' : ''">
        {{ value }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appDonutProgressBar',
  props: {
    status: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: 0
    }
  },
  methods: {
    setPercentFill: (percent) => {
      const number = parseInt(percent)
      return `${119.32 - number / 100 * 119.32}`
    }
  }
}
</script>

<style lang="scss" scoped>
.donut-progress {
  color: $color__gray-blue;
  display: flex;
  flex-flow: column;
  align-items: center;

  &__svg {
    position: relative;
  }

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    @include font20();
    color: $color__main;
    font-weight: $fw_bold;
    transition: $transition;

    &.deactive {
      color: $color__gray-blue;
    }
  }

  .donut-progress__inner-bg-circle {
    fill: $color__background--card;
  }

  .donut-progress__progress-bg-circle {
    fill: $color__gray-blue;
  }

  .donut-progress__progress-circle {
    transition: $transition;
    stroke: $color__main;
    transform-origin: center;
    transform: rotate(-90deg);
  }
}
</style>
