<template>
  <ModalForm
    v-model="formData"
    title="Редактирование партии"
    class="modal--title-reset"
    @request="updateHandler"
    @reject="onReject"
  >
    <VInput
      label="Количество изделий"
      name="value"
      required
      :pattern="/^\d+$/"
      :maxValue="consignment.warehouse ? countMaterialByArticle : null"
      :minValue="1"
      class="mb-30"
    >
      <Validation for="required">Поле обязательно</Validation>
      <Validation for="pattern">Только цифры</Validation>
      <Validation v-if="consignment.warehouse" for="maxValue">Количество не может превышать {{countMaterialByArticle}}</Validation>
      <Validation for="minValue">Количество не может быть меньше 1</Validation>
    </VInput>

    <VSelect
      v-if="!consignment.is_without_winding"
      label="Способ навивки"
      class="mb-30"
      name="winding_method"
      required
      :options="windingMethodOptionsFiltered"
    >
      <Validation for="required"/>
    </VSelect>

    <VSelect
      label="Исполнитель"
      name="executor"
      class="mb-30"
      :options="executorList"
    />
  </ModalForm>
</template>
<script>
import VSelect from '@/components/Form/VSelect.vue'
import Validation from '@/components/Form/Validation.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import { formulaTotalWeight } from '@/utils/production/position/formulas'
import { fixedNumberCopy } from '@/utils/number'
import { prepareFormDataId } from '@/utils/form/prepareFormDataId'
import { mapGetters } from 'vuex'
import { getUserFullName } from '@/utils/model/User'

export default {
  name: 'ModalConsignmentUpdate',
  components: {
    ModalForm,
    VInput,
    Validation,
    VSelect
  },
  props: {
    consignment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formData: {
        value: this.consignment.value,
        winding_method: this.consignment.winding_method,
        executor: this.consignment.executor?.id ?
          {
            name: getUserFullName(this.consignment.executor),
            id: this.consignment.executor.id
          } : null
      }
    }
  },

  computed: {
    ...mapGetters({
      executorList: 'users/executorList',
      countMaterialByArticle: 'warehouse/countMaterialByArticle'
    }),

    windingMethodOptionsFiltered () {
      return this.$store.state.records.properties.winding_method.filter(windingMethod => {
        if (!windingMethod.workshops?.length) return false

        const workshopId = this.position?.workshop_id || this.consignment.position?.workshop_id

        for (const windingMethodWorkshop of windingMethod.workshops) {
          if (workshopId === windingMethodWorkshop.id) return true
        }

        return false
      })
    }
  },

  methods: {
    updateHandler (data) {
      const articleData = {
        value_article: this.consignment.value - data.value,
        article: this.consignment.position?.article || ''
      }

      // TODO DELETE ?
      const calcWeight = formulaTotalWeight(this.consignment.position)
      const weightClear = fixedNumberCopy((calcWeight * data.value), 3)

      data = {
        ...articleData,
        ...this.consignment,
        id: this.consignment.id,
        status_id: this.consignment.status?.id,
        position_id: this.consignment.position_id,
        ...prepareFormDataId(data, ['winding_method', 'executor']),
        weight_clear: weightClear
      }

      this.$emit('updateHandler', data)
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
