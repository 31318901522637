<template>
  <AppAccordion class="box" init-is-open>
    <div slot="header" class="box__header">
      <h3>Этапы</h3>

      <VButton
        v-if="permissionUpdatePosition && !isCreateStage"
        text="Редактировать"
        size="sm"
        icon="edit"
        @click.native="TOGGLE_IS_EDIT_STAGE"
      />
    </div>

    <VForm v-model="formData" ref="VFormWorkshop" class="mb-30" scroll-when-invalid>   
      <VSelect
        label="Цех производства"
        width="290px"
        name='workshop'
        :options="properties.workshop"
        :disabled="(!isEditStage && !isCreateStage) || (!(positionConsignments.length === 0) && positionConsignmentsCount)"
        required
      >
        <Validation for='required' />
      </VSelect>
    </VForm>

    <VCheckbox
      v-if="isCreateStage"
      v-model="isTemplateStage"
      label="Выбрать из шаблона"
      class="mb-16"
    />

    <VSelect
      v-if="isCreateStage"
      label="Шаблон"
      v-model="templateValue"
      class="mb-30"
      width="290px"
      :disabled="!isTemplateStage"
      :options="templateStageLineList"
      @change="changeStatuses"
    />

    <VForm class="box__body" @request="submitCreateOrEditStages">
      <FormStageLineItem
        v-for="(item, i) in modelStatuses"
        :key="i"
        ref="stageItemRef"
        class="mb-30"
        :index="i"
        :disabled="!isEditStage && !isCreateStage"
        :stageItem="item"
        scroll-when-invalid
        @deleteItem="checkStageHasConsignment(i)"
        @changeStageData="changeStageData"
      />

      <VButton
        v-if="isEditStage || isCreateStage"
        text="Добавить этап"
        class="mb-30"
        size="sm"
        color="primary-sm"
        icon="create"
        @click.native="addStage(modelStatuses.length + 1)"
      />

      <div v-if="isEditStage || isCreateStage" class="buttons">
        <VButton
          :text="isCreateStage ? 'Создать' : 'Сохранить'"
          color="primary"
          size="xl"
          type="submit"
          :loading="isLoading"
        />

        <VButton
          text="Отмена"
          size="xl"
          @click.native="resetStage"
        />
      </div>
    </VForm>
  </AppAccordion>
</template>

<script>
import AppAccordion from '@/components/ui/AppAccordion'
import FormStageLineItem from '@/components/production/stage/FormStageLineItem'
import VSelect from '@/components/Form/VSelect'
import VForm from '@/components/Form/VForm'
import VButton from '@/components/simple/button/VButton'
import VCheckbox from '@/components/Form/VCheckbox'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PositionApi from '@/api/production/Position/PositionApi'
import { defaultStageList } from '@/classes/StageItemModel'
import { createStageLineFormData } from '@/utils/pages/Production/stageLine/createStageLineFormData'
import { hasRepeatStage } from '@/utils/pages/Production/stageLine/hasRepeatStage'
import { isValidStageLine } from '@/utils/pages/Production/stageLine/isValidStageLine'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import Validation from '@/components/Form/Validation.vue'
import { showAlertError, showAlertSuccess, showAlertWarning } from '@/utils/store/alert'

export default {
  name: 'StageCreateBlock',
  components: {
    Validation,
    AppAccordion,
    VForm,
    FormStageLineItem,
    VSelect,
    VButton,
    VCheckbox
  },
  mixins: [mixinIsLoading],

  data: () => ({
    isTemplateStage: false,
    templateValue: null,
    formData: {
      workshop: null
    }
  }),

  computed: {
    ...mapState('productions/template/stageLine', {
      templateStageLineList: 'all'
    }),
    ...mapGetters('orderDetail', {
      statuses: 'statuses',
      currentPositionId: 'currentPositionId',
      isCreateStage: 'isCreateStage',
      isEditStage: 'isEditStage',
      positionConsignments: 'positionConsignments'
    }),
    ...mapGetters({
      properties: 'records/propertiesAll'
    }),
    ...mapGetters('permission', [
      'permissionUpdatePosition'
    ]),

    modelStatuses: {
      get () {
        return this.statuses
      },
      set (newStatuses) {
        this.$store.commit('orderDetail/SET_POSITION_STATUS_LINE_VALUE', newStatuses)
      }
    },

    positionConsignmentsCount () {
        let isValue = false
        this.positionConsignments.forEach(item => {
            if (item.value !== 0) isValue = true
        })
        return isValue
    }
  },

  watch: {
    currentPositionId () {
      this.syncFormData()
      this.isTemplateStage = false
      this.templateValue = null
    }
  },

  mounted () {
    this.syncFormData()
  },

  beforeDestroy () {
    this.$store.commit('orderDetail/SET_STAGE_STORE', [])
    this.$store.commit('orderDetail/SET_POSITION_STATUS_LINE_VALUE', [...defaultStageList])
  },

  methods: {
    ...mapMutations('orderDetail', [
      'CHANGE_IS_EDIT_STAGE',
      'TOGGLE_IS_EDIT_STAGE',
      'SET_CURRENT_POSITION_WORKSHOP',
      'SET_POSITION_STATUS_LINE_VALUE',
      'ADD_STATUS_BY_STATUS_LINE'
    ]),
    ...mapActions('orderDetail', {
      createStageLine: 'createStageLine',
      updateStageLine: 'updateStageLine'
    }),

    async submitCreateOrEditStages () {
      if (this.$refs.VFormWorkshop.hasValidationErrors()) return

      if (!isValidStageLine(this.$refs.stageItemRef)) return

      const stageLineFormData = createStageLineFormData(this.$refs.stageItemRef)

      if (hasRepeatStage(stageLineFormData)) {
        return showAlertWarning('Нельзя, чтобы на линии этапов повторялись этапы. Измените линию этапов или создайте новый этап в справочнике.')
      }

      try {
        this.startLoading()

        const data = {
          workshop_id: this.formData.workshop.id
        }

        await PositionApi.patchWorkshop(this.currentPositionId, data)
        this.SET_CURRENT_POSITION_WORKSHOP(this.formData.workshop)

        if (this.isCreateStage) {
          await this.createStageLine(stageLineFormData)
        }
        if (this.isEditStage) {
          await this.updateStageLine(stageLineFormData)
        }

        showAlertSuccess('Данные сохранены')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    checkStageHasConsignment (index) {
      const stageId = this.modelStatuses[index].status?.id

      if (!stageId) {
        return this.deleteItem(index)
      }

      for (const consignment of this.positionConsignments) {
        if (consignment.status.id === stageId) {
          return showAlertWarning('На данном этапе находятся партии. Переместите партии, прежде чем удалить данный этап.')
        }
      }

      this.deleteItem(index)
    },

    deleteItem (idx) {
      this.statuses.splice(idx, 1)
      this.$store.commit('orderDetail/SET_POSITION_STATUS_LINE_VALUE', this.statuses)
    },

    changeStageData (data, index) {
      const payload = {
        data,
        index
      }
      this.SET_POSITION_STATUS_LINE_VALUE(payload)
    },

    addStage (index) {
      this.ADD_STATUS_BY_STATUS_LINE(index)
    },

    resetStage () {
      if (this.isCreateStage) {
        this.$store.commit('orderDetail/SET_POSITION_STATUS_LINE_VALUE', [...defaultStageList])
        this.isTemplateStage = false
        this.templateValue = null
      } if (this.isEditStage) {
        const temp = this.$store.state.orderDetail.tempStatuses
        const temp1 = JSON.parse(JSON.stringify(temp))
        this.$store.commit('orderDetail/SET_POSITION_STATUS_LINE_VALUE', temp1)
        this.$store.commit('orderDetail/CHANGE_IS_EDIT_STAGE', false)
      }
    },

    changeStatuses () {
      if (!this.templateValue?.statuses?.length) return
      this.$store.commit('orderDetail/SET_POSITION_STATUS_LINE_VALUE', [...this.templateValue.statuses])
    },

    syncFormData () {
      this.formData.workshop = this.$store.getters['orderDetail/currentPosition'].workshop
    }
  }
}
</script>
