export const POSITION_FIELDS_HANDBOOK = [
  'type',
  'responsible',
  'material',
  'test',
  'tests',
  'folds',
  'flatness',
  'chamfer',
  'winding_type',
  'winding_dir',
  'winding_method',
  'surface_treatment',
  'coating',
  'hook_type_1',
  'hook_type_2',
  'hook_orientation_1',
  'hook_orientation_2',
  'reference_turn_1',
  'reference_turn_2',
  'color',
  'shape'
]

export const POSITION_FIELDS_COMMA_TO_DOT = [
  'number_full_turns',
  'number_turns',
  'number_turn_cylinder',
  'number_turn_cone',
  'sweep_length',
  'hook_gap_1',
  'hook_gap_2',
  'hook_length_2',
  'gap',
  'd_wire',
  'd_outer_1',
  'd_outer_2',
  'd_inner',
  'body_length',
  'overall_length',
  'length_by_hooks',
  'hook_length_1',
  'tap_length_1',
  'tap_length_2',
  'step'
]

export const POSITION_PRINT_TYPE_LIST = [
  { value: 'passport-quality', title: 'Паспорт' },
  { value: 'box-slider', title: 'Стикер' }
]
