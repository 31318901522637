export const TABLE_HEAD = [
  {
    title: 'Дата изменений',
    name: 'created_at'
  },
  {
    title: 'Кто изменил',
    name: 'changed'
  },
  {
    title: 'Событие',
    name: 'event'
  },
  {
    title: 'Место изменений',
    name: 'placeChanged'
  }
]

export const MODAL_TH = [
  {
    title: 'Место изменений',
    name: 'placeChanged'
  },
  {
    title: 'Было',
    name: 'itWas'
  },
  {
    title: 'Стало',
    name: 'became'
  }
]

export const MODAL_ALL_TH = [
  {
    title: 'Дата изменений',
    name: 'created_at'
  },
  {
    title: 'Кто изменил',
    name: 'changed'
  },
  {
    title: 'Событие',
    name: 'event'
  },
  {
    title: 'Место изменений',
    name: 'placeChanged'
  }
]
