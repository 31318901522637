export const mixinIsOpen = {
  data: () => ({
    isOpen: false
  }),
  methods: {
    turnOnIsOpen () {
      this.isOpen = true
    },
    turnOffIsOpen () {
      this.isOpen = false
    },
    toggleIsOpen () {
      this.isOpen = !this.isOpen
    }
  }
}
