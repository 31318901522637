<template>
  <div class="table__link-list gap-xxs">
    <router-link
      class="link link--no-line"
      v-for="(item, i) of list"
      :key="i"
      target="_blank"
      :to="{ name: routerData.pageName, params: getLinkParams(item) }"
    >
      {{ routerData.textLink }}
      {{ item[routerData.dataKey] || item }}{{ (i !== list.length - 1) ? ';' : ''}}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    routerData: {
      type: Object,
      required: true
    }
  },

  methods: {
    getLinkParams (item) {
      return this.routerData.paramsKey && this.routerData.paramsValueKey ? { [this.routerData.paramsKey]: item[this.routerData.paramsValueKey] } : { id: item.id }
    }
  }
}
</script>
