import { mapGetters } from 'vuex'

export const mixinConsignment = {
  computed: {
    ...mapGetters({
      currentUserId: 'currentUserId'
    }),
    ...mapGetters('permission', {
      isBrigadier: 'isBrigadier',
      isExecutor: 'isExecutor',
      isProductionMaster: 'isProductionMaster'
    }),

    additionalVerificationPermissionUpdateConsignmentStage () {
      let permission = true

      if (this.isBrigadier || this.isProductionMaster) {
        permission = this.currentUserId === this.consignment?.responsible?.id || this.currentUserId === this.consignment?.executor?.id
      }

      if (this.isExecutor) {
        permission = this.currentUserId === this.consignment?.executor?.id
      }

      return permission
    }
  }
}
