<template>
  <InfoText label="Ранее деланные заказы">
    <ul v-if="relationOrders.length" class="link-list flex f-wrap gap-xxs">
      <li
        v-for="(item, index) in relationOrders"
        :key="index"
        class="link-list__item"
      >
        <router-link v-if="item.id" class="link" :to="{ name: 'OrderDetails', params: { id: item.id } }">{{ item.account_number }}</router-link>
        <span v-else>{{ item.account_number }}</span>
      </li>
    </ul>

    <span v-else>-</span>
  </InfoText>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText.vue'
import { orderModel } from '@/entities/order'

export default {
  name: 'OrderRelationOrders',
  components: { InfoText },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    relationOrders () {
      return orderModel.getRelationOrders(this.order)
    }
  }
}
</script>

<style lang='scss'>
.link-list {
  &__item {
    &::after {
      content: ';';
    }
  }
}
</style>
