<template>
  <ModalForm
    title="Назначить исполнителя"
    class="modal--title-reset"
    @request="addExecutorHandler"
    @reject="onReject"
  >
    <VSelect
      label="Исполнитель"
      name="executor"
      class="mb-30"
      :options="executorList"
      required
    >
      <Validation for="required"/>
    </VSelect>
  </ModalForm>
</template>
<script>
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import Validation from '@/components/Form/Validation.vue'
import VSelect from '@/components/Form/VSelect.vue'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalConsignmentAddExecutor',
  components: {
    VSelect,
    Validation,
    ModalForm
  },

  props: {
    consignmentId: {
      type: [String, Number],
      required: true
    }
  },

  computed: {
    ...mapGetters({
      executorList: 'users/executorList'
    })
  },

  methods: {
    onReject () {
      this.$emit('reject')
    },

    async addExecutorHandler (data) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        const executor = {
          executor_id: data.executor?.id
        }

        const response = await ConsignmentController.updateExecutor(this.consignmentId, executor)
        if (response?.status !== 200) return

        this.$emit('addExecutorHandler', response.data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    }
  }
}
</script>
