import { throwResponseError } from '@/utils/error/throwResponseError'
import { getAll } from '@/api/request'

const CHANGELOG_URL = 'history/all'

export const ChangelogApi = {
  // params
  // subject_id - id сущности
  // subject_type * - модель
  // sort
  getAll: async (params) => {
    try {
      return await getAll(CHANGELOG_URL, params)
    } catch (e) {
      throwResponseError(e, 'Не удалось загрузить журнал изменений')
    }
  }
}
