<template>
  <VForm v-model="modelStageData" ref="stageForm">
    <div :class="['stage-item', !disabled ? 'stage-item--with-delete' : '']">
      <VSelect
        label="Этап"
        name="status"
        placeholder="Выбрать"
        class="stage-item__select"
        :options="stageList"
        item-text="name"
        required
        :disabled="isRequiredStage || disabled"
      >
        <Validation for="required">Поле обязательно</Validation>
      </VSelect>

      <div v-if="!isRequiredStage" class="stage-item__arrow">
        <svgicon
          icon="arrow-long"
          width="25px"
        />
      </div>

      <VSelect
        v-if="!isRequiredStage"
        label="Ответственный"
        name="user"
        placeholder="Выбрать"
        class="stage-item__select"
        :options="stageResponsibleList"
        item-text="name"
        :required="isNotRequiredName"
        :disabled="disabled"
      >
        <Validation for="required">Поле обязательно</Validation>
      </VSelect>

      <button
        v-if="!disabled && index !== 0 && !isRequiredStage"
        class="stage-item__btn-remove btn-reset btn-cross btn-cross--remove"
        type="button"
        @click="deleteItem"
      />
    </div>
  </VForm>
</template>

<script>
import VSelect from '@/components/Form/VSelect'
import VForm from '@/components/Form/VForm'
import Validation from '@/components/Form/Validation'
import { mapGetters } from 'vuex'

export default {
  name: 'FormStageLineItem',
  components: { VSelect, VForm, Validation },
  props: {
    stageItem: {
      type: Object,
      default: () => {}
    },
    index: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isNotRequiredName: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      stageData: this.stageItem
    }
  },

  model: {
    prop: 'stageData',
    emit: 'changeStageData'
  },

  computed: {
    ...mapGetters({
      stageList: 'records/stageList',
      stageResponsibleList: 'users/stageResponsibleList'
    }),

    modelStageData: {
      get () {
        return this.stageItem
      },
      set (newStageData) {
        this.stageData = newStageData
        this.$emit('changeStageData', newStageData, this.index)
      }
    },

    isRequiredStage () {
      switch (this.stageItem?.status?.flag_id) {
        case 5:
        case 4:
        case 3: return true
      }

      return false
    }
  },
  methods: {
    deleteItem () {
      this.$emit('deleteItem')
    },
    isValid () {
      return this.$refs.stageForm.hasValidationErrors()
    }
  }
}
</script>

<style lang="scss" scoped>
.stage-item {
  position: relative;
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 25px 1fr;
  gap: 4%;

  &--with-delete {
    padding-right: 30px;
  }

  &__arrow {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__btn-remove {
    position: absolute;
    bottom: 15px;
    right: 10px;
    width: 10px;
    height: 10px;
  }
}

.stage-item + .stage-item {
  margin-top: 10px;
}
</style>
