export const mixinIsEdit = {
  data: () => ({
    isEdit: false
  }),
  methods: {
    turnOnIsEdit () {
      this.isEdit = true
    },
    turnOffIsEdit () {
      this.isEdit = false
    },
    toggleEdit () {
      this.isEdit = !this.isEdit
    }
  }
}
