<template>
  <div class="box__no-content">
    <svgicon
      class="svg-icon--logo"
      icon="logo-img"
      width="60"
    />

    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>
