<template>
  <VForm
    v-model="formData"
    class="column gap-s"
    @request="submitForm"
  >
    <div class="form-grid--col-3">
      <VInput
        :label="ELabels.sender"
        name="sender"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.delivery"
        name="delivery"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.order_account_number"
        name="order_account_number"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.recipient"
        name="recipient"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.before"
        name="before"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.comment"
        name="comment"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.TIN"
        name="TIN"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.address"
        name="address"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.payment_method"
        name="payment_method"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.payer"
        name="payer"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.phone"
        name="phone"
        :disabled="isLoading"
      />

      <VInput
        :label="ELabels.account_double"
        name="account_double"
        :disabled="isLoading"
      />
    </div>

    <ButtonGroup
      textBtnConfirm="Сохранить"
      textBtnReject="Отмена"
      btn-size="l"
      :loading="isLoading"
      @reject="$emit('cancel')"
    />
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup.vue'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { WaybillApi } from '@/api/production/waybill/WaybillApi'
import { waybillModel } from '@/entities/waybill'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'

export default {
  components: {
    ButtonGroup,
    VInput,
    VForm
  },
  mixins: [mixinIsLoading],

  props: {
    orderId: {
      type: Number,
      required: true
    },
    waybill: {
      type: waybillModel.Waybill
    }
  },

  data: () => ({
    formData: {
      sender: null,
      delivery: null,
      order_account_number: null,
      recipient: null,
      before: null,
      comment: null,
      TIN: null,
      address: null,
      payment_method: null,
      payer: null,
      phone: null,
      account_double: null
    }
  }),

  computed: {
    ELabels: () => waybillModel.ELabels
  },

  created () {
    if (!this.waybill) return

    this.syncFormData()
  },

  methods: {
    async submitForm () {
      try {
        this.startLoading()

        const data = this.getMapped(this.formData)

        if (this.waybill) {
          await this.updateWaybill(data)
        } else {
          await this.createWaybill(data)
        }

        showAlertSuccess('Информация по отгрузке обновлена')
        this.$emit('update-success')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    async createWaybill (data) {
      const response = await WaybillApi.create(data)
      this.$store.commit('waybill/SET_WAYBILL', response.data)
    },

    async updateWaybill (data) {
      const response = await WaybillApi.update(this.waybill.id, data)
      this.$store.commit('waybill/UPDATE_WAYBILL', response.data)
    },

    getMapped (formData) {
      return {
        ...formData,
        tin: formData.TIN,
        order_id: this.orderId
      }
    },

    syncFormData () {
      for (const key in this.formData) {
        if (!this.waybill[key]) continue

        this.formData[key] = this.waybill[key]
      }
    }
  }
}
</script>
