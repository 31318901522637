export const mixinArticles = {
  computed: {
    articlesAll () {
      return this.$store.state.records.articlesAll
    }
  },

  methods: {
    findTemplatePositionBy (article) {
      return this.articlesAll.position_templates.find(templatePosition => templatePosition.article === article)
    },

    findProductBy (article) {
      return this.articlesAll.products.find(product => product.article === article)
    }
  }
}
