<template>
  <ModalForm
    v-model="formData"
    title="Создать новую партию"
    class="modal--title-reset"

    text-btn-confirm="Создать"
    btn-size="xl"
    only-btn-submit

    @request="consignmentCreateHandler"
    @reject="onReject"
  >
    <div v-if="countMaterial" class="mb-16">
      <VCheckbox
        v-model="isAddFromArticle"
        label="Добавить из задела"
        class="mb-8"
      />

      <p class="color--green">
        В наличии: <span>{{ countMaterial }}</span>
      </p>
    </div>

    <VInput
      label="Количество изделий"
      name="value"
      class="mb-30"
      required
      :pattern="/^\d+$/"
      :min-value="minVal"
      :maxValue="maxVal"
    >
      <Validation for="required"/>
      <Validation for="pattern" key-text="num"/>
      <Validation for="minValue" :count="minVal"/>
      <Validation for="maxValue" :count="maxVal"/>
    </VInput>

    <VCheckbox
      v-if="$store.getters['permission/permissionCreateConsignmentWithoutWending']"
      v-model="isWithoutWinding"
      label="Услуга без навивки"
      class="mb-30"
    />

    <VSelect
      v-show="!isWithoutWinding"
      label="Способ навивки"
      class="mb-30"
      name="winding_method"
      :required="!isWithoutWinding"
      :options="windingMethodOptionsFiltered"
    >
      <Validation for="required"/>
    </VSelect>

    <VInput
      v-if="probesNeed"
      :label="'Необходимое кол-во пробников: ' + probesNeed"
      name="probes"
      class="mb-30"
      :pattern="/^\d+$/"
      :maxValue="probesNeed"
    >
      <Validation for="pattern" key-text="num"/>
      <Validation for="maxValue" :count="probesNeed"/>
    </VInput>

    <VSelect
      v-if="isAddFromArticle"
      label="Направить на этап"
      class="mb-30"
      name="stage"
      required
      :options="stageLine"
    >
      <Validation for="required"/>
    </VSelect>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import Validation from '@/components/Form/Validation'
import VCheckbox from '@/components/Form/VCheckbox'
import VInput from '@/components/Form/Vinput/VInput'
import VSelect from '@/components/Form/VSelect'
import { mapActions } from 'vuex'
import { prepareFormDataId } from '@/utils/form/prepareFormDataId'
import { showAlertWarning } from '@/utils/store/alert'

export default {
  name: 'ModalConsignmentCreate',
  components: { VCheckbox, VSelect, Validation, VInput, ModalForm },
  props: {
    position: {
      type: Object,
      required: true
    },
    stageLine: {
      type: Array,
      required: true
    },
    countMaterial: {
      type: [Number, null],
      default: 0
    }
  },
  data: () => ({
    formData: {
      probes: ''
    },
    isAddFromArticle: false,
    isWithoutWinding: false
  }),
  computed: {
    springWaiting () {
      return this.position.waiting_start
    },
    probesNeed () {
      if (!this.position?.probes) return 0
      return +this.position.probes - this.position.probes_already
    },
    minVal () {
      if (!this.probesNeed) return 1
      if (this.formData.probes) return +this.formData.probes
      if (this.springWaiting > this.probesNeed) return 1
      return this.probesNeed
    },
    maxVal () {
      if (!this.isAddFromArticle) return this.springWaiting

      if (this.countMaterial > this.springWaiting) {
        return this.springWaiting
      }
      return this.countMaterial
    },

    windingMethodOptionsFiltered () {
      return this.$store.state.records.properties.winding_method.filter(windingMethod => {
        if (!windingMethod.workshops?.length) return false

        for (const windingMethodWorkshop of windingMethod.workshops) {
          if (this.position?.workshop_id === windingMethodWorkshop.id) return true
        }

        return false
      })
    }
  },
  methods: {
    ...mapActions('orderDetail', {
      createConsignment: 'createConsignment'
    }),

    async consignmentCreateHandler (data) {
      if (this.checkNeedProbes(+data.value, data.probes)) {
        return showAlertWarning('Необходимо добавить пробники')
      }

      if (!this.isWithoutWinding) {
        data = prepareFormDataId(data, ['winding_method'])
      }

      data.is_without_winding = this.isWithoutWinding

      if (this.isAddFromArticle) {
        data.article = this.currentPosition?.article
        data.warehouse = true
        data.stageId = data?.stage.id || null
      }

      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        await this.createConsignment(data)
        this.$emit('request')
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
        this.isAddFromArticle = false
      }
    },

    checkNeedProbes (val, probes) {
      if (!this.probesNeed) return false

      if ((this.springWaiting - +val) < this.probesNeed) {
        if (!probes) return true

        if (+probes < this.probesNeed) return true
      }
      return false
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
