<template>
  <div class="table-tree__item" style="display: contents">
    <transition name="fade">
      <tr
        v-show="isOpen"
        class="table-tree__tr"
        :class="classListRow"
        @click="isCategory ? openChilds() : null"
      >
        <td
          v-for="(col, index) in columns"
          :key="index"
          :style="index === 0 ? styleFirstTd : null"
          class="table-tree__td"
        >
          <span
            v-if="isCategory && index === 0"
            class="table-tree__icon"
            :class="isOpenChild ? 'table-tree__icon--opened' : ''"
          >
            <svgicon
              icon="button/rollup"
              width="13"
              height="9"
            />
          </span>

          <span
            v-if="!col.isLink || item.isNotLink"
            :class="isCategory ? 'bold' : ''"
            v-html="(isCategory && index !== 0 && !item.isFillRow) ? '' : col.handlerFormat ? col.handlerFormat(item[col.name]) : item[col.name]"
          />

          <TableLinkList
            v-if="!isCategory && col.isLink && Array.isArray(item[col.name])"
            :list="item[col.name]"
            :router-data="col.routerData"
          />
        </td>
      </tr>
    </transition>

    <!--  категории  -->
    <transition-group
      v-show="isOpen && isOpenChild"
      tag="div"
      style="display: contents"
    >
      <TableTreeItem
        v-for="(child, i) of categoryList"
        :key="i"
        :count-item="i + countItem + 1"
        :columns="columns"
        :item="child"
        :lvl="lvl + 1"
        :children-keys="childrenKeys"
        :is-open="isOpenChild"
        :visible-child="visibleChild"
      />
    </transition-group>

    <!--  наполнение  -->
    <transition-group
      v-show="isOpen && isOpenChild"
      tag="div"
      style="display: contents"
    >
      <TableTreeItem
        v-for="(child, i) of categoryItemList"
        :key="i"
        :count-item="i + countItem + 1"
        :columns="columns"
        :item="child"
        :lvl="lvl + 1"
        :children-keys="childrenKeys"
        :is-open="isOpenChild"
        :visible-child="visibleChild"
      />
    </transition-group>
  </div>
</template>

<script>
import TableLinkList from '@/components/ui/Table/TableLinkList'
import { mixinTableItem } from '@/mixins/table/mixinTableItem'

export default {
  name: 'TableTreeItem',
  components: {
    TableLinkList
  },
  mixins: [mixinTableItem],
  props: {
    lvl: {
      type: Number,
      required: false,
      default: 1
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: true
    },
    visibleChild: {
      type: Boolean,
      required: false,
      default: true
    },
    countItem: {
      type: Number,
      required: false,
      default: 1
    },
    // для указания под какими ключами находятся категории и наполнение категорий
    childrenKeys: {
      type: Object,
      required: false,
      default: () => ({
        category: 'children_categories',
        categoryItem: 'positions'
      })
    }
  },
  data: () => ({
    isOpenChild: true
  }),
  computed: {
    isCategory () {
      return this.item[this.childrenKeys.category]?.length || this.item[this.childrenKeys.categoryItem]?.length
    },

    categoryList () {
      if (this.item[this.childrenKeys.category]?.length) {
        return this.item[this.childrenKeys.category]
      }
      return []
    },

    categoryItemList () {
      if (this.item[this.childrenKeys.categoryItem]?.length) {
        return this.item[this.childrenKeys.categoryItem]
      }
      return []
    },

    isOdd () {
      return this.countItem % 2
    },

    classListRow () {
      const classes = []

      if (this.isCategory) {
        classes.push('table-tree__tr--category')
      }
      if (this.isOdd) {
        classes.push('table-tree__tr--odd')
      }

      return classes
    },

    styleFirstTd () {
      const pixelAdd = this.isCategory ? 0 : 10
      return {
        'padding-left': 20 * this.lvl + pixelAdd + 'px'
      }
    }
  },
  watch: {
    visibleChild: {
      handler (bool) {
        this.isOpenChild = bool
      },
      immediate: true
    }
  },
  methods: {
    openChilds () {
      this.isOpenChild = !this.isOpenChild
    }
  }
}
</script>
