<template>
  <div>
    <AppAccordion
      class="box order-position"
      init-is-open
      :visible-btn="!!hasPositions"
    >
      <div slot="header">
        <header class="box__header">
          <h3>Позиции в заказе</h3>

          <div class="buttons buttons--card-full">
            <slot name="header-buttons" />

            <VButton
              v-if="permissionCreatePosition"
              text="Создать позицию"
              icon="create"
              size="sm"
              color="primary-sm"
              @click.native="openModal('create-position')"
            />

            <DropdownMenu v-if="showDropdownMenu">
              <template slot="dropdownItems">
                <VButton
                  v-if="permissionUpdatePosition"
                  text="Редактировать"
                  icon="edit"
                  size="sm"
                  @click.native="TOGGLE_IS_EDIT_POSITION"
                />

                <VButton
                  v-if="permissionCreatePositionTemplate"
                  text="Создать шаблон"
                  icon="create"
                  size="sm"
                  @click.native="openModal('create-template')"
                />

                <VButton
                  v-if="isCopyPosition"
                  text="Копировать позицию"
                  icon="copy"
                  size="sm"
                  @click.native="openModal('copy-position')"
                />

                <VButton
                  v-if="permissionDestroyPosition && !positionConsignments.length"
                  text="Удалить"
                  icon="remove"
                  size="sm"
                  @click.native="openModal('position-delete')"
                />
              </template>
            </DropdownMenu>
          </div>
        </header>

        <NoContentBox
          v-if="!hasPositions"
          text="Позиция не создана"
        />

        <TabsSwiper
          v-if="hasPositions"
          class="tabs-swiper--border mb-30"
          :space-between="30"
        >
          <TabItem
            class="swiper-slide"
            v-for="(item, index) of tabsPositions"
            :key="item.id"
            :navItem="item"
            :is-active="activePositionIndex === index"
            @click.native="setActivePosition(index)"
          />
        </TabsSwiper>
      </div>

      <div v-if="hasPositions">
        <PositionHeadForm
          ref="PositionHeadForm"
          class="mb-m"
          :is-disabled="isDisabled"
        />

        <PositionFormDetails
          v-model="modelFormData"
          class="mb-s"
          ref="positionFormRef"
          :typePosition="currentPosition.type"
          has-probes
          :isDisabled="isDisabled"
          @copyNoteManufacture="copyNoteManufacture"
          @copyRelativeOrders="copyRelativeOrders"
          @copyNoteOTK="copyNoteOTK"
        />

        <ButtonGroup
          textBtnConfirm="Сохранить"
          textBtnReject="Отмена"
          btn-size="l"
          :loading="isLoading"
          :disabled="isDisabled"
          @confirm="submitUpdatePosition"
          @reject="resetForm"
        />
      </div>
    </AppAccordion>

    <ModalPositionCreate v-if="isModal === 'create-position'" @close="closeModal" />
    <ModalPositionCopy v-if="isModal === 'copy-position'" @close="closeModal" />
    <!-- position delete -->
    <ModalConfirmation
      v-if="isModal === 'position-delete'"
      title="Уверены что хотите удалить позицию?"
      @confirm="positionDeleteHandler"
      @reject="closeModal"
    />
    <CreateTemplateFromPosition
      v-if="isModal === 'create-template'"
      :position="currentPositionActive"
      @close="closeModal"
    />
  </div>
</template>

<script>
import AppAccordion from '@/components/ui/AppAccordion'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup'
import ModalPositionCreate from '@/components/production/position/ModalPositionCreate.vue'
import ModalPositionCopy from '@/components/production/position/ModalPositionCopy.vue'
import NoContentBox from '@/components/production/NoContentBox'
import PositionFormDetails from '@/components/production/position/PositionFormDetails'
import PositionHeadForm from '@/components/production/position/PositionHeadForm.vue'
import TabItem from '@/components/ui/TabsSwiper/TabItem'
import TabsSwiper from '@/components/ui/TabsSwiper/TabsSwiper'
import VButton from '@/components/simple/button/VButton'

import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formulaTotalWeight } from '@/utils/production/position/formulas'
import { fixedNumberCopy } from '@/utils/number'
import { prepareFormDataPosition } from '@/utils/production/position'
import { showAlertSuccess } from '@/utils/store/alert'
import { orderModel } from '@/entities/order'

import { reclamationExternalModel } from '@/entities/reclamation-external'
import CreateTemplateFromPosition from '@/features/create-template-from-position/ui/CreateTemplateFromPosition.vue'
import DropdownMenu from '@/components/ui/DropdownMenu.vue'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'

export default {
  name: 'Positions',
  components: {
    ModalConfirmation,
    DropdownMenu,
    CreateTemplateFromPosition,
    PositionHeadForm,
    AppAccordion,
    ButtonGroup,
    ModalPositionCreate,
    NoContentBox,
    PositionFormDetails,
    TabItem,
    TabsSwiper,
    VButton,
    ModalPositionCopy
  },
  mixins: [mixinModal, mixinIsLoading],

  mounted () {
    this.setActivePosition(this.activePositionIndex)
    this.getPropertiesAll()
  },

  watch: {
    'modelFormData.article' (val) {
      if (!val) return
      this.setArticle(val)
    }
  },

  computed: {
    ...mapGetters('orderDetail', {
      order: 'order',
      positions: 'positions',
      currentPosition: 'currentPosition',
      isEditPosition: 'isEditPosition',
      totalDirtyWeightConsignments: 'totalDirtyWeightConsignments',
      activePositionIndex: 'activePositionIndex',
      tabsPositions: 'tabsPositions',
      positionConsignments: 'positionConsignments',
      currentPositionActive: 'currentPositionActive'
    }),
    ...mapGetters({
      productionMasterList: 'users/productionMasterList',
      countMaterialByArticle: 'warehouse/countMaterialByArticle'
    }),
    ...mapGetters('permission', [
      'permissionCreatePosition',
      'permissionCopyPosition',
      'permissionUpdatePosition',
      'permissionDestroyPosition',
      'permissionCreatePositionTemplate'
    ]),

    modelFormData: {
      get () {
        const position = this.currentPosition
        if (position.type?.id === 3 && (position.tap_angle === undefined)) position.tap_angle = 0
        return position
      },
      set (newFormData) {
        this.SET_CURRENT_POSITION_VALUE({ value: newFormData })
      }
    },

    hasPositions () {
      return this.positions?.length
    },

    isDisabled () {
        let isValue = false
        if (this.positionConsignments) {
            this.positionConsignments.forEach(item => {
                if (item.value !== 0) isValue = true
            })
        }
        if (isValue && !this.isEditPosition) return true
        else return this.disabled || !this.isEditPosition
    },

    calcTotalWeight () {
      return formulaTotalWeight(this.modelFormData)
    },

    weightPosition () {
      let weightTotal = this.calcTotalWeight ? this.calcTotalWeight * this.modelFormData.value : 0
      if (weightTotal !== 0) {
        weightTotal = fixedNumberCopy(weightTotal, 2)
      }
      return weightTotal
    },
    isCopyPosition () {
      if (!this.order?.is_outer_reclamation || !this.order?.from_outer_reclamation?.indicator || !this.permissionCopyPosition) {
        return false
      }

      return reclamationExternalModel.isIndicatorForCopyPosition(this.order.from_outer_reclamation.indicator)
    },
    showDropdownMenu () {
      return this.permissionUpdatePosition
        || this.isCopyPosition
        || this.permissionCreatePositionTemplate
        || (this.permissionDestroyPosition && !this.positionConsignments.length)
    }
  },

  methods: {
    ...mapMutations({
      setArticle: 'warehouse/SET_ARTICLE'
    }),
    ...mapMutations('orderDetail', [
      'SET_CURRENT_POSITION',
      'SET_CURRENT_POSITION_VALUE',
      'SET_CURRENT_POSITION_CANCEL_EDIT',
      'SET_CURRENT_POSITION_FILES_DELETE',
      'TOGGLE_IS_EDIT_POSITION'
    ]),
    ...mapActions('orderDetail', [
      'updatePositionCurrent',
      'setActivePosition',
      'removePosition'
    ]),

    ...mapActions({
      getPropertiesAll: 'records/getPropertiesAll'
    }),

    async submitUpdatePosition () {
      if (this.hasValidErrors()) return

      try {
        this.startLoading()

        const data = {
          ...this.modelFormData,
          ...prepareFormDataPosition(this.modelFormData)
        }
        await this.updatePositionCurrent(data)

        this.modelFormData.files = this.currentPosition.files

        showAlertSuccess('Позиция обновлена')
      } finally {
        this.finishLoading()
      }
    },

    hasValidErrors () {
      let error = false
      if (this.$refs.PositionHeadForm.$refs.Form.hasValidationErrors()) {
        error = true
      }

      if (this.$refs.positionFormRef.$refs.positionFormRef.hasValidationErrors()) {
        error = true
      }

      return error
    },

    clearForm () {
      this.$refs.PositionHeadForm.$refs.Form.clear()
      this.$refs.positionFormRef.$refs.positionFormRef.clear()
    },

    resetForm () {
      this.SET_CURRENT_POSITION_CANCEL_EDIT()
      this.$emit('cancelEdit')
      this.modelFormData = { ...this.currentPosition }
      this.clearForm()
    },

    validationPositionValue (value) {
      const consignments = this.modelFormData.consignments
      if (!consignments?.length) return true

      const consignmentsValue = consignments.reduce((prev, current) => {
        return prev + current.value
      }, 0)

      return +value >= consignmentsValue
    },

    copyRelativeOrders () {
      const relationOrders = orderModel.getRelationOrders(this.order)
      let text = 'Ранее деланные заказы: '
      if (!relationOrders.length) text = text + '-; '
      else {
        relationOrders.forEach(order => {
            text = text + order.account_number + '; '
        })
      }
      this.setComment(text)
    },

    copyNoteManufacture () {
      let note
      if (!this.order.note_manufacture) note = '-; '
      else note = this.order.note_manufacture + '; '
      this.setComment(note)
    },

    setComment (text) {
      if (!this.modelFormData.comment) this.modelFormData.comment = ''
      this.modelFormData.comment = `${this.modelFormData.comment} ${text}`.trim()
    },

    copyNoteOTK () {
        let otk
        if (!this.order.note_otk) otk = '-; '
        else otk = this.order.note_otk + '; '
        this.setNoteOTK(otk)
    },

    setNoteOTK (text) {
      if (!this.modelFormData.note_otk) this.modelFormData.note_otk = ''
      this.modelFormData.note_otk = `${this.modelFormData.note_otk} ${text}`.trim()
    },

    // Position
    async positionDeleteHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        await this.removePosition()
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
        this.closeModal()
      }
    }
  }
}
</script>
