export const mixinTimeout = {
  data: () => ({
    timeoutId: null
  }),
  methods: {
    setTimeoutId (handler, timeout) {
      this.timeoutId = setTimeout(handler, timeout)
    },
    clearTimeoutId () {
      clearTimeout(this.timeoutId)
    }
  }
}
