import { instance } from '@/api'
import { throwResponseError } from '@/utils/error/throwResponseError'

const REPORT_URL = 'report'

export const ReportApi = {
  async getBy (type, params) {
    try {
      return await instance.get(`/${REPORT_URL}/${type}`, { params })
    } catch (e) {
      throwResponseError(e, 'Не удалось получить документ')
    }
  }
}
