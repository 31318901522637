<template>
  <transition name="fade">
    <div class="stage-line-implement">
      <div class="stage-line-implement__header">
        <div class="d-flex ai-c gap-xs">
          <svgicon
            icon="spring"
            class="svg-icon--spring"
            width="15"
            height="18"
          />

          <span>№ Партии: {{ stageImplement.sort }}</span>
        </div>

        <svgicon
          v-if="isConsignmentReclamation"
          icon="notification-error"
          class="svg-icon--notification-error svg-icon--red"
          width="18px"
        />
      </div>
      <div class="stage-line-implement__body">
        <p class="stage-line-implement__position-title">{{ positionTitle }}</p>
        <p class="stage-line-implement__count"><span>Кол-во:</span>&nbsp;{{ stageImplement.value }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'StageLineImplementCard',
  props: {
    stageImplement: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    positionTitle () {
      const { type, value } = this.stageImplement.position
      const id = this.stageImplement.position.sort

      return `№${id || ''} ${type?.name || ''} (${value || ''})`.trim()
    },
    isConsignmentReclamation () {
      return this.stageImplement.inner_reclamations?.length
    }
  }
}
</script>

<style lang="scss" scoped>
.stage-line-implement {
  padding: 16px 10px;
  background: var(--bg-color__box);;
  box-shadow: 3.84851px 3.84851px 6.41419px rgba(151, 181, 230, 0.3), -3.84851px -3.84851px 6.41419px #FFFFFF;
  border-radius: 12.8284px;
  display: inline-block;

  &__header {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: var(--letter-spacing);
    color: $color__main;
  }

  &__body {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: var(--letter-spacing);
  }
  &__position-title {
    margin-bottom: 8px;
  }
  &__count {
    span {
      color: $color__main;
    }
  }
}
</style>
