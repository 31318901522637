function createStageLineFormData (stageItemArrayRef) {
  return stageItemArrayRef.map(({ stageData }, index) => ({
    sort: index + 1,
    status_id: stageData.status?.id || null,
    user_id: stageData.user?.id || null
  }))
}

export {
  createStageLineFormData
}
