<template>
  <div class="date-status">
    <div class="date-status__text" v-if="!inConsTable">{{ text }}</div>
    <div class="date-status__date" :class="getStatusClass">
      {{ dateFinished | date('date') }}
    </div>
  </div>
</template>

<script>
const EOrderDateStatus = {
  fail: 'fail',
  warning: 'warning',
  normal: 'normal'
}

export default {
  name: 'OrderDateStatus',
  props: {
    order: Object,
    inConsTable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text () {
      switch (this.statusType) {
        case EOrderDateStatus.fail: return 'Просрочен'
        case EOrderDateStatus.warning: return 'Срок подходит'
        default: return ''
      }
    },

    statusType () {
      if (this.order.finished_at) {
        switch (this.order.ready_status) {
          case 1: return EOrderDateStatus.normal
          case 2: return EOrderDateStatus.warning
          case 3: return EOrderDateStatus.fail
        }
      }

      if (this.daysToFinish < 0) {
        return EOrderDateStatus.fail
      }

      if (this.daysToFinish >= 0 && this.daysToFinish <= this.daysForWarning) {
        return EOrderDateStatus.warning
      }

      return EOrderDateStatus.normal
    },

    daysForWarning () {
      return Math.round(this.timingDays * 0.25)
    },

    daysToFinish () {
      const nowDT = Date.now()
      const nowT = nowDT % (3600 * 1000 * 24)
      const now = new Date(nowDT - nowT)
      const finishedDateDT = Date.parse(this.dateFinished)
      const finishedDateT = finishedDateDT % (3600 * 1000 * 24)
      const finishedDate = new Date(finishedDateDT - finishedDateT)
      return (finishedDate - now) / (3600 * 1000 * 24)
    },

    dateFinished () {
      return this.order.date_finished
    },

    timingDays () {
      return this.order.timing ? this.order.timing.days : 0
    },

    getStatusClass () {
      return 'date-status__date--' + this.statusType + (this.inConsTable ? ' in_cons_table' : '')
    }
  }
}
</script>

<style lang="scss" scoped>
.date-status {
  &__text {
    color: var(--color__gray-blue);
    @include font12;
  }

  &__date {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @include font14;

    &:after {
      content: '';
      display: block;
      @include size(12px);
      background: currentColor;
      border-radius: 50%;
      margin-left: 6px;
      transform: translateY(-1px);
    }

    &.in_cons_table {
      @include font16;
      &:after {
        display: none;
      }
    }

    &--fail {
      color: $color__red;
    }

    &--warning {
      color: $color__orange;
    }

    &--normal {
      color: $color__green;
    }
  }
}
</style>
