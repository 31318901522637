<template>
  <div class="radio-group">
    <label v-if="label" class="radio-group__label">{{ label }}</label>

    <div class="radio-group__options">
      <Radio
        v-for="(option, idx) in options"
        :key="idx"
        :name="name"
        :option="option"
        :size="size"
        :loading="loading"
        v-model="inputValue"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Base from '../Vinput/Base/Base.vue'
import Radio from './Radio'

export default Vue.extend({
  extends: Base,
  components: {
    Radio
  },
  inject: {
    formData: {
      from: 'formData',
      default: null
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      defaultValue: this.options[0]?.value ?? ''
    }
  },
  computed: {
    inputValue: {
      get () {
        if (this.name) {
          return this.formData[this.name]
        } else {
          return this.value
        }
      },
      set (val) {
        if (this.name) {
          this.formData[this.name] = val
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    inputValue (value) {
      this.$emit('change', value)
    }
  }
})
</script>

<style lang="scss">
.radio-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  &__label {
    align-self: flex-start;
    color: $color__input--font;
    font-size: 12px;
    margin-bottom: 8px;
  }

  &__options {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  &--row {
    .radio-group__options {
      flex-direction: row;
    }
  }
}
</style>
