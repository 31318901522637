<template>
  <ModalConfirmation
    @confirm="removeHandler"
    @reject="onReject"
  />
</template>

<script>
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import { mapActions } from 'vuex'

export default {
  name: 'ModalConsignmentRemove',
  components: { ModalConfirmation },
  props: {
    consignmentId: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    ...mapActions('consignment', {
      DELETEConsignment: 'DELETEConsignment'
    }),

    async removeHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const response = await this.DELETEConsignment(this.consignmentId)
        if (response?.status !== 204) return

        this.$emit('remove')
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },
    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
