<template>
  <div @click="click" class="radio" :class="{ disabled: disabled }">
    <input
      v-model="inputValue"
      ref="radio"
      type="radio"
      :name="name"
      :value="option.value"
      :checked="active"
      :disabled="option.disabled"
      hidden
    />

    <span class="radio__icon" :class="{ active }"/>

    <span class="radio__title">{{ option.title }}</span>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  mounted () {
    if (!this.loading) {
      this.$refs.radio.checked = this.active
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    option: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    active () {
      return this.inputValue === this.option.value
    },
    inputValue: {
      get () {
        return this.value
      },
      set () {
        this.$emit('input', this.option.value)
      }
    },
    disabled () {
      return !!this.option.disabled
    }
  },
  methods: {
    click () {
      if (!this.loading) {
        this.$refs.radio.click()
      }
    }
  }
})
</script>

<style lang="scss">
.radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .1s;
  transition-timing-function: ease;

  &:hover {
    .radio__icon {
      border-color: $color__main;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    width: 20px;
    height: 20px;
    border: 1px solid $color__gray--light;
    border-radius: 100%;
    transition-property: border-color;
    transition-duration: $transition;
    transition-timing-function: ease-in-out;

    &::after {
      content: '';
      @include absoluteCenter;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      background-color: $color__main;
      visibility: hidden;
      opacity: 0;
      transition-property: opacity, visibility;
      transition-duration: $transition;
      transition-timing-function: ease-in-out;
    }

    &.active {
      border: 1px solid $color__gray--light;

      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    color: $color__gray--light;
  }
}
</style>
