<template>
  <ModalForm
    :title="title"
    text-btn-confirm="Печать"
    only-btn-submit
    @request="onRequest"
    @reject="onReject"
  >
    <div class="d-flex flex-col gap-s ai-c mb-30">
      <RadioGroup
        :options="printOptions"
        name="type"
        :value="initPrintType"
      />

      <slot/>
    </div>
  </ModalForm>

</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import RadioGroup from '@/components/Form/Radio/RadioGroup'
import { openPrint } from '@/utils/print/openPrint'
import { showAlertWarning } from '@/utils/store/alert'

export default {
  name: 'ModalPrint',
  components: { RadioGroup, ModalForm },
  props: {
    title: {
      type: String,
      default: 'Печать'
    },
    printParams: {
      type: [Object, null],
      default: null
    },
    printOptions: {
      type: Array,
      required: true
    },
    initPrintType: {
      type: String,
      default: ''
    }
  },
  methods: {
    async onRequest (data) {
      if (!data.type) {
        showAlertWarning('Пожалуйста, выберете тип документа для печати')
      }

      if (!this.printParams) {
        return this.$emit('request', data)
      }

      console.log(this.printParams)

      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        const response = await openPrint(data.type, this.printParams)

        if (response?.status !== 200) return

        const file = this.getDataFile(response.data)
        this.$emit('request', file)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    getDataFile (data) {
      return {
        url: data.url,
        name: data.url.split('/').at(-1)
      }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
