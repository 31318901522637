function hasRepeatStage (stageLineFormData) {
  const map = new Map()

  for (let i = 0; i < stageLineFormData.length - 3; i++) {
    const statusId = stageLineFormData[i].status_id

    if (map.get(statusId)) {
      return true
    }

    map.set(statusId, 1)
  }

  return false
}

export {
  hasRepeatStage
}
