import { getUserFullName } from '@/utils/model/User'
import { formatDateTime } from '@/utils/format/date'

export function mapData (item) {
  return {
    created_at: formatDateTime(item.created_at) || '-',
    changed: getUserFullName(item.user) || '-',
    event: item.event || '-',
    placeChanged: item.placeChanged || '-',
    properties: item.properties,
    id: item.id
  }
}

export function mapItemToDetails (item) {
  return mapItem(item)
    .filter(r => r !== undefined)
    .flatMap(r => r)
}

function mapItem (data) {
  if (data.properties) {
    let newValues
    if (data.event === 'Удаление') {
      newValues = Object.values(data.properties.old)
        .filter(value => value || false)
        .map(() => ({ became: '-' }))
    } else {
      newValues = Object.values(data.properties?.attributes).map(value => ({
        became: value
      }))
    }

    let attributes
    if (data.event === 'Удаление') {
      attributes = Object.keys(data.properties.old)
        .filter(key => data.properties.old[key] || false)
        .map(key => ({ placeChanged: key }))
    } else {
      attributes = Object.keys(data.properties?.attributes).map(key => ({
        placeChanged: key
      }))
    }

    let olds
    if (data.properties?.old) {
      if (data.event === 'Удаление') {
        olds = Object.values(data.properties.old)
          .filter(value => value || false)
          .map(value => ({ itWas: value }))
      } else {
        olds = Object.values(data.properties.old).map(value => ({
          itWas: value
        }))
      }
    } else {
      olds = Object.values(data.properties?.attributes).map(() => ({
        itWas: '-'
      }))
    }

    return attributes.map((attr, index) => {
      return Object.assign({}, attr, olds[index], newValues[index])
    })
  }
  return {}
}
